import styled from "styled-components";
import backgroundImg from '../../img/otterbg2.jpg'
import cloud from '../../img/cloud.png'

export const RoadmapArea = {
    id: 'roadmap',
}

export const RoadmapContainer = styled.div`
    
    background-color: #3ab0df;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    height: fit-content;
    position: relative;
    z-index: 4;
    margin-top: 0px;
    text-align: center;
    overflow: hidden;


    @media screen and (max-width: 960px)
    {
        transition: 0.8s all ease;
    }
`

export const RoadmapWrapper = styled.div`
background-image: url(${cloud});
position: absolute;
align-items: center;
text-align: center;
height: 100%;
background-position-x: center;
background-position-y: center;
background-size: 100%;
background-repeat: no-repeat;
padding: 50px;
line-height: flex;
`



export const Milestone = styled.div`
position: relative;
font-size: 150%;
color: black;
padding-top: 25px;

justify-content: center;
`

export const RoadmapText = styled.div`
background-image: url(${cloud});
position: relative;
font-size: 27px;
line-height: 2;

padding-top: 0;
top: -20px;
color: black;
display: flex;
flex-direction: column;

background-position-x: center;
background-position-y: center;
background-size: 100%;
background-repeat: no-repeat;
padding-bottom: 55px;


    @media screen and (max-width: 900px)
    {
        transition: 0.8s all ease;
        line-height: 2.2;
        font-size: 13px;
        padding-top: 10px;
    }

@media screen and (max-width: 760px)
    {
        transition: 0.8s all ease;
        line-height: 2.45;
        font-size: 10px;
        padding-top: 10px;
    }
`
