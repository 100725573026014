import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrap, SidebarLink, SidebarMenu } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrap>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to="roadmap" onClick={toggle}>
                        Roadmap
                    </SidebarLink>
                    <SidebarLink to="team" onClick={toggle}>
                        Team
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrap>
        </SidebarContainer>
    )
}

export default Sidebar;
